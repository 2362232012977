var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('ValidationObserver',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveItem.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name:")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.name),expression:"formPayload.name"}],staticClass:"form-control",class:classes,attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Category Name"},domProps:{"value":(_vm.formPayload.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "name", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"categories"}},[_vm._v("Category: ")]),_c('validation-provider',{attrs:{"name":"categories","rules":"required"}},[_c('v-select',{attrs:{"id":"categories","label":"name","options":_vm.categories,"placeholder":"-- Pilih Category --","reduce":function (categories) { return categories.id; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.searchCategory.apply(null, arguments)}},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Image / File:")]),_c('br'),_c('validation-provider',{attrs:{"name":"image","rules":"required"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".jpeg, .png, .jpg, .pdf"},on:{"change":function($event){return _vm.changeFile($event)}}}),_c('small',[_vm._v("Current file:")]),_c('br'),(
                  (_vm.file_url !== null && _vm.file_type == 'image') ||
                  _vm.file_type == 'jpeg' ||
                  _vm.file_type == 'png' ||
                  _vm.file_type == 'jpg'
                )?_c('img',{staticClass:"media_file",attrs:{"crossorigin":"anonymous","src":_vm.file_url}}):(_vm.file_url !== null && _vm.file_type == 'pdf')?_c('div',{staticClass:"d-flex align-items-center mt-1"},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"FileIcon","size":"18"}}),_c('a',{attrs:{"href":_vm.file_url,"download":""}},[_vm._v(" Link download file ")])],1):_vm._e()],1)],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_title"}},[_vm._v("Meta Title:")]),_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.meta_title),expression:"formPayload.meta_title"}],staticClass:"form-control",class:classes,attrs:{"id":"meta_title","type":"text","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.formPayload.meta_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "meta_title", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_title"}},[_vm._v("Meta Description:")]),_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.meta_description),expression:"formPayload.meta_description"}],staticClass:"form-control",class:classes,attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"rows":"4"},domProps:{"value":(_vm.formPayload.meta_description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "meta_description", $event.target.value)}}})]}}])})],1)])],1),_c('b-col',{staticClass:"p-0 mt-2",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary",attrs:{"variant":"primary","disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.isLoading ? "Submitting..." : "Submit")+" ")]),_vm._v("     "),_c('a',{staticClass:"btn waves-effect waves-float waves-light btn-secondary",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.handleBackButton()}}},[_vm._v(" Cancel ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }